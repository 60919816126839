<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Login v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <img
                        src="@/assets/images/logo/logo.png"
                        alt="Logo"
                        class="logo-auth"
                    />
                    <h2 class="brand-text text-primary ml-1">Leechall.io</h2>
                </b-link>

                <!-- alert -->
                <b-alert :variant="variant" show v-if="status">
                    <div class="alert-body">{{ status }}</div>
                </b-alert>

                <!-- form -->
                <validation-observer ref="loginForm" #default="{ invalid }">
                    <b-form
                        class="auth-login-form mt-2"
                        @submit.prevent="onSubmit"
                    >
                        <!-- email -->
                        <b-form-group label-for="email" label="Email">
                            <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="email"
                                    name="login-email"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="john@example.com"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <label for="password">Password</label>
                                <b-link :to="{ name: 'auth-forgot' }">
                                    <small>Forgot Password?</small>
                                </b-link>
                            </div>
                            <validation-provider
                                #default="{ errors }"
                                name="password"
                                rules="required|min:6"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        class="form-control-merge"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-password"
                                        placeholder="Password"
                                    />

                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- <vue-recaptcha
                            class="mt-1"
                            ref="reCaptcha"
                            :sitekey="sitekey"
                            @verify="onLogin"
                            @expired="onExpired"
                            size="invisible"
                        /> -->

                        <vue-hcaptcha
                            class="mt-1"
                            :sitekey="sitekey"
                            size="invisible"
                            ref="hCaptcha"
                            @verify="onLogin"
                            @expired="onExpired"
                        />

                        <!-- overlay -->
                        <b-overlay
                            :show="loading"
                            rounded
                            opacity="0.6"
                            spinner-variant="primary"
                        >
                            <!-- submit button -->
                            <b-button
                                variant="primary"
                                type="submit"
                                block
                                :disabled="invalid"
                            >
                                Sign in
                            </b-button>
                        </b-overlay>
                    </b-form>
                </validation-observer>
                <b-card-text class="text-center mt-2">
                    <span>New on our platform? </span>
                    <b-link :to="{ name: 'auth-register' }">
                        <span>Create an account</span>
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BOverlay,
    BAlert,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
} from "bootstrap-vue";
import { required, email, min } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
    components: {
        // BSV
        BOverlay,
        BAlert,
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
        VueRecaptcha,
        VueHcaptcha,
        required,
        email,
        min,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            sitekey: process.env.VUE_APP_HCAPTCHA,
            email: "",
            password: "",
            variant: "",
            status: "",
            loading: false,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
    },
    methods: {
        onExpired() {
            this.$refs.hCaptcha.reset();
        },
        onSubmit() {
            this.$refs.hCaptcha.execute();
        },
        onLogin(response) {
            this.status = "";
            this.loading = true;
            axios
                .post("auth/login", {
                    email: this.email,
                    password: this.password,
                    "h-captcha-response": response,
                })
                .then((response) => {
                    if (response.data.success) {
                        this.variant = "success";
                        this.status = "Logged in successfully.";
                        this.$store.dispatch(
                            "setToken",
                            response.data.access_token
                        );
                        setTimeout(() => {
                            if (this.$route.query.next === undefined) {
                                window.location.href = "/";
                            } else {
                                window.location.href = this.$route.query.next;
                            }
                        }, 1000);
                    } else {
                        this.loading = false;
                        this.variant = "danger";
                        this.status = response.data.message;
                    }
                    this.onExpired();
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.data.errors != undefined) {
                        if (
                            "h-captcha-response" in error.response.data.errors
                        ) {
                            this.variant = "danger";
                            this.status =
                                error.response.data.errors[
                                    "h-captcha-response"
                                ][0];
                        } else {
                            this.$refs.loginForm.setErrors(
                                error.response.data.errors
                            );
                        }
                    }
                    this.onExpired();
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
